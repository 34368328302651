angular.module('app')
.service('currentOrganisation', ["$rootScope", "$q", "authenticatedStatus", function($rootScope, $q, authenticatedStatus) {

    this.getOrganisationReference = function() {
        var defer = $q.defer();

        if (!_.isEmpty($rootScope.department)) {
            defer.resolve($rootScope.department.organisationReference);
        } else {
            authenticatedStatus.user.promise.then(function (user) {
                defer.resolve(user.props.organisation);
            });
        }

        return defer.promise;
    };
}]);